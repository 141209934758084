import { createAsyncThunk } from "@reduxjs/toolkit"
import md5 from "md5"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchUsers = createAsyncThunk(
  "users/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("users/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchUserParents = createAsyncThunk(
  "users/fetchesparent",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("users/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchUser = createAsyncThunk(
  "users/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("users/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const exportUser = createAsyncThunk(
  "users/export",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("users/export", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const downloadUserImportTemplate = createAsyncThunk(
  "users/downloadUserImportTemplate",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("users/import-template", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const importUser = createAsyncThunk(
  "users/importUser",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()
      if (request.document) {
        formData.append('file', request.document, request.document.name)
      }
      const response = await httpInstance.post('users/import', formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveUser = createAsyncThunk(
  "users/save",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('company_id', request.company_id)
      formData.append('role_id', request.role_id)
      formData.append('nid', request.nid)
      formData.append('name', request.name)
      formData.append('phone', request.phone)
      formData.append('email', request.email)
      formData.append('position', request.position)
      formData.append('parent_nid', request.parent_nid)
      formData.append('is_active', request.is_active)
      if (!request.id) { 
        formData.append('password', md5(request.password))
        formData.append('confirm_password', md5(request.confirm_password))
      }
      formData.append('expired_date', request.expired_date)
      if (request.photo) {
        formData.append('photo', request.photo, request.photo.name)
      }

      request.districts.map(district => {
        formData.append('districts[]', district)
      })
      
      const action = request.id ? `edit/${request.id}` : "add"
      const response = await httpInstance.post(`users/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteUser = createAsyncThunk(
  "users/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`users/delete/${request.query.id}`, null, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
