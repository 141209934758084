import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchRoles } from "../../repositories/roleRepository"
  
  const dataAdapter = createEntityAdapter()
  const roleSlice = createSlice({
    name: "datasource/roles",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchRoles.fulfilled]: (roles, action) => {
        if (action.payload.hasOwnProperty("roles")) {
          dataAdapter.setAll(roles, action.payload.roles)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          roles.pagination = action.payload.pagination
        }
      }
    }
  })
  export default roleSlice.reducer
  
  const selftState = (state) => state.datasource.role
  export const getRoleIds = dataAdapter.getSelectors(selftState).selectIds
  export const getRoles = dataAdapter.getSelectors(selftState).selectEntities
  export const getRoleById = (id) => createSelector(
    state => dataAdapter.getSelectors(state => state.datasource.role).selectById(state, id),
    role => role
  )
  export const getRolePagination = createSelector(selftState, role => role.pagination)
  