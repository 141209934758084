import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchMyTransactions } from "../../repositories/equipmentEffectivenessRepository"
  
const dataAdapter = createEntityAdapter()
const myTransactionSlice = createSlice({
    name: "datasource/myTransactions",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchMyTransactions.fulfilled]: (myTransactions, action) => {
        if (action.payload.hasOwnProperty("equipmentEffectivenesses")) {
          dataAdapter.setAll(myTransactions, action.payload.equipmentEffectivenesses)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          myTransactions.pagination = action.payload.pagination
        }
      }
    }
})
export default myTransactionSlice.reducer
  
const selftState = state => state.datasource.myTransaction
export const getMyTransactionIds = dataAdapter.getSelectors(selftState).selectIds
export const getMyTransactions = dataAdapter.getSelectors(selftState).selectEntities
export const getMyTransactionPagination = createSelector(selftState, myTransaction => myTransaction.pagination)
  