import {
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit"
import { fetchSpareparts } from "../../repositories/sparepartRepository"

const dataAdapter = createEntityAdapter()
const sparepartSlice = createSlice({
  name: "datasource/spareparts",
  initialState: dataAdapter.getInitialState({
    loading: false,
    pagination: {}
  }),
  reducers: {
    unloaded: spareparts => {
      dataAdapter.removeAll(spareparts, {})
    }
  },
  extraReducers: {
    [fetchSpareparts.fulfilled]: (spareparts, action) => {
      if (action.payload.hasOwnProperty("spareparts")) {
        dataAdapter.setAll(spareparts, action.payload.spareparts)
      }

      if (action.payload.hasOwnProperty("pagination")) {
        spareparts.pagination = action.payload.pagination
      }
    }
  }
})
export default sparepartSlice.reducer

const selftState = (state) => state.datasource.sparepart
export const unloadSparepart = () => sparepartSlice.actions.unloaded()
export const getSparepartIds = dataAdapter.getSelectors(selftState).selectIds
export const getSpareparts = dataAdapter.getSelectors(selftState).selectEntities
export const getSparepartById = (id) => createSelector(
  state => dataAdapter.getSelectors(state => state.datasource.sparepart).selectById(state, id),
  sparepart => sparepart
)
export const getSparepartPagination = createSelector(selftState, sparepart => sparepart.pagination)
