import axios from "axios"

// const httpInstance = axios.create({ baseURL: "http://192.168.18.94:81/api/v1/" })
// const httpInstance = axios.create({ baseURL: "http://localhost:8000/api/v1/" })
const httpInstance = axios.create({ baseURL: "https://apiptpjb.findproject.net/api/v1/" })
export default httpInstance

export const httpConfiguration = () => {
  let authorization
  if (localStorage.getItem("accessToken") !== null) {
    authorization = {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("accessToken"))
      }`
    }
  }

  return {
    headers: {
      ...authorization
    }
  }
}