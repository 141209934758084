import { createAsyncThunk } from "@reduxjs/toolkit"
import moment from "moment"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchNavigations = createAsyncThunk(
  "navigations/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("navigations/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchNavigation = createAsyncThunk(
  "navigations/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("navigations/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const exportNavigation = createAsyncThunk(
  "navigations/export",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get(`navigations/export/${request.id}`, {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveNavigation = createAsyncThunk(
  "navigations/save",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.navigations.map((navigation, index) => {
        formData.append(`navigations[${index}][equipment_id]`, navigation.equipment_id)
        formData.append(`navigations[${index}][actual_coordinate]`, navigation.actual_coordinate)
        formData.append(`navigations[${index}][position_status]`, navigation.position_status)
        formData.append(`navigations[${index}][inspection_result]`, navigation.inspection_result)
        formData.append(`navigations[${index}][indicator_status]`, navigation.indicator_status)
        formData.append(`navigations[${index}][notes]`, navigation.notes)
        if (navigation.document) {
          formData.append(`navigations[${index}][document]`, navigation.document, navigation.document.name)
        } else { 
          formData.append(`navigations[${index}][file_name]`, navigation.file_name)
          formData.append(`navigations[${index}][file_size]`, navigation.file_size)
          formData.append(`navigations[${index}][file_type]`, navigation.file_type)
          formData.append(`navigations[${index}][uploaded_file_name]`, navigation.uploaded_file_name)
          formData.append(`navigations[${index}][asset_path]`, navigation.asset_path)
          formData.append(`navigations[${index}][asset_url]`, navigation.asset_url)
        }
        formData.append(`navigations[${index}][document_control]`, navigation.document_control)
      })
      
      const action = request.id ? `edit/${request.id}` : "add"
      const response = await httpInstance.post(`navigations/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const approveNavigation = createAsyncThunk(
  "navigations/approve",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.navigations.map((navigation, index) => {
        formData.append(`navigations[${index}][equipment_id]`, navigation.equipment_id)
        formData.append(`navigations[${index}][actual_coordinate]`, navigation.actual_coordinate)
        formData.append(`navigations[${index}][position_status]`, navigation.position_status)
        formData.append(`navigations[${index}][inspection_result]`, navigation.inspection_result)
        formData.append(`navigations[${index}][indicator_status]`, navigation.indicator_status)
        formData.append(`navigations[${index}][notes]`, navigation.notes)
        if (navigation.document) {
          formData.append(`navigations[${index}][document]`, navigation.document, navigation.document.name)
        } else { 
          formData.append(`navigations[${index}][file_name]`, navigation.file_name)
          formData.append(`navigations[${index}][file_size]`, navigation.file_size)
          formData.append(`navigations[${index}][file_type]`, navigation.file_type)
          formData.append(`navigations[${index}][uploaded_file_name]`, navigation.uploaded_file_name)
          formData.append(`navigations[${index}][asset_path]`, navigation.asset_path)
          formData.append(`navigations[${index}][asset_url]`, navigation.asset_url)
        }
        formData.append(`navigations[${index}][document_control]`, navigation.document_control)
      })
      
      const action = `approve/${request.id}`
      const response = await httpInstance.post(`navigations/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteNavigation = createAsyncThunk(
  "navigations/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`navigations/delete/${request.query.id}`, null, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
