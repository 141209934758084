import { createAsyncThunk } from "@reduxjs/toolkit"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchEquipment = createAsyncThunk(
  "equipment/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("equipment/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchEquipmentSingle = createAsyncThunk(
  "equipment/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("equipment/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchTreeEquipment = createAsyncThunk(
  "equipment/fetchesTree",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("equipment/tree-model", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchSubEquipment = createAsyncThunk(
  "subEquipment/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("equipment/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchEquipmentSparepart = createAsyncThunk(
  "subEquipment/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("equipment/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const exportEquipment = createAsyncThunk(
  "equipment/export",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("equipment/export", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const downloadEquipmentImportTemplate = createAsyncThunk(
  "equipment/donwloadImportTemplate",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("equipment/import-template", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const importEquipment = createAsyncThunk(
  "equipment/import",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()
      if (request.document) {
        formData.append('file', request.document, request.document.name)
      }
      const response = await httpInstance.post('equipment/import', formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveEquipment = createAsyncThunk(
  "equipment/save",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }

      formData.append('company_id', request.company_id) 
      formData.append('district_id', request.district_id) 
      formData.append('entity_id', request.entity_id) 
      formData.append('unit_id', request.unit_id) 
      formData.append('code', request.code) 
      formData.append('name', request.name) 
      formData.append('row_number', request.row_number) 
      formData.append('parent_code', request.parent_code) 
      formData.append('category', request.category) 
      formData.append('section_label', request.section_label) 
      formData.append('relation_type', request.relation_type) 
      formData.append('point', request.point) 
      formData.append('is_calculated', request.is_calculated) 
      formData.append('component', request.component) 
      formData.append('design_coordinate', request.design_coordinate) 
      formData.append('light_inspection_criteria', request.light_inspection_criteria) 
      formData.append('not_ready_minimum_value', request.not_ready_minimum_value) 
      formData.append('some_disturbance_minimum_value', request.some_disturbance_minimum_value) 
      formData.append('is_equipment', request.isEquipment) 
      
      formData.append('spareparts', [])
      if (request.spareparts.length > 0) {
        request.spareparts.map(sparepartId => formData.append('spareparts[]', sparepartId))
      }

      if (request.document) {
        formData.append(`document`, request.document, request.document.name)
      } else { 
        formData.append(`file_name`, request.file_name)
        formData.append(`file_size`, request.file_size)
        formData.append(`file_type`, request.file_type)
        formData.append(`uploaded_file_name`, request.uploaded_file_name)
        formData.append(`asset_path`, request.asset_path)
        formData.append(`asset_url`, request.asset_url)
      }
      
      const action = request.id ? `edit/${request.id}` : "add"
      const response = await httpInstance.post(`equipment/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteEquipment = createAsyncThunk(
  "equipment/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`equipment/delete/${request.query.id}`, null, httpConfiguration())
      
      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
