import { createAsyncThunk } from "@reduxjs/toolkit"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchCompanies = createAsyncThunk(
  "companies/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("companies/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchCompany = createAsyncThunk(
  "companies/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("companies/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveCompany = createAsyncThunk(
  "companies/save",
  async (request, thunkAPI) => {
    try {
      const formData = { ...request }
      const action = formData.id ? `edit/${formData.id}` : "add"
      const response = await httpInstance.post(`companies/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteCompany = createAsyncThunk(
  "companies/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`companies/delete/${request.query.id}`, null, httpConfiguration())

      return response.data
    } catch (exception) {
      console.log(exception)
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
