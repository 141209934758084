import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchFenders } from "../../repositories/fenderRepository"
  
  const dataAdapter = createEntityAdapter()
  const fenderSlice = createSlice({
    name: "datasource/fenders",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchFenders.fulfilled]: (fenders, action) => {
        if (action.payload.hasOwnProperty("fenders")) {
          dataAdapter.setAll(fenders, action.payload.fenders)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          fenders.pagination = action.payload.pagination
        }
      }
    }
  })
  export default fenderSlice.reducer
  
  const selftState = (state) => state.datasource.fender
  export const getFenderIds = dataAdapter.getSelectors(selftState).selectIds
  export const getFenders = dataAdapter.getSelectors(selftState).selectEntities
  export const getFenderById = (id) => createSelector(
    state => dataAdapter.getSelectors(state => state.datasource.fender).selectById(state, id),
    fender => fender
  )
  export const getFenderPagination = createSelector(selftState, fender => fender.pagination)
  