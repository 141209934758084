import { createAsyncThunk } from "@reduxjs/toolkit"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchSpareparts = createAsyncThunk(
  "spareparts/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("spareparts/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchSparepartsByEquipment = createAsyncThunk(
  "spareparts/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("spareparts/get-spareparts-from-equipment", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchSparepart = createAsyncThunk(
  "spareparts/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("spareparts/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const exportSparepart = createAsyncThunk(
  "spareparts/export",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("spareparts/export", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const downloadSparepartImportTemplate = createAsyncThunk(
  "spareparts/donwloadImportTemplate",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("spareparts/import-template", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const importSparepart = createAsyncThunk(
  "spareparts/import",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()
      if (request.document) {
        formData.append('file', request.document, request.document.name)
      }
      const response = await httpInstance.post('spareparts/import', formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveSparepart = createAsyncThunk(
  "spareparts/save",
  async (request, thunkAPI) => {
    try {
      const formData = { ...request }
      const action = formData.id ? `edit/${formData.id}` : "add"
      const response = await httpInstance.post(`spareparts/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteSparepart = createAsyncThunk(
  "spareparts/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`spareparts/delete/${request.query.id}`, null, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
