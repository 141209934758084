import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchMyTasks } from "../../repositories/equipmentEffectivenessRepository"
  
const dataAdapter = createEntityAdapter()
const myTaskSlice = createSlice({
    name: "datasource/myTasks",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchMyTasks.fulfilled]: (myTasks, action) => {
        if (action.payload.hasOwnProperty("equipmentEffectivenesses")) {
          dataAdapter.setAll(myTasks, action.payload.equipmentEffectivenesses)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          myTasks.pagination = action.payload.pagination
        }
      }
    }
})
export default myTaskSlice.reducer
  
const selftState = state => state.datasource.myTask
export const getMyTaskIds = dataAdapter.getSelectors(selftState).selectIds
export const getMyTasks = dataAdapter.getSelectors(selftState).selectEntities
export const getMyTaskPagination = createSelector(selftState, myTask => myTask.pagination)
  