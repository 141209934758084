import { createAsyncThunk } from "@reduxjs/toolkit"
import moment from "moment"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchCoalConveyorSystems = createAsyncThunk(
  "coalConveyorSystems/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("coal-conveyor-systems/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchCoalConveyorSystem = createAsyncThunk(
  "coalConveyorSystems/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("coal-conveyor-systems/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const exportCoalConveyorSystem = createAsyncThunk(
  "coalConveyorSystems/export",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get(`coal-conveyor-systems/export/${request.id}`, {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveCoalConveyorSystem = createAsyncThunk(
  "coalConveyorSystems/save",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.coal_conveyor_systems.map((coalConveyorSystem, index) => {
        formData.append(`coal_conveyor_systems[${index}][equipment_id]`, coalConveyorSystem.equipment_id)
        formData.append(`coal_conveyor_systems[${index}][physical_condition]`, coalConveyorSystem.physical_condition)
        formData.append(`coal_conveyor_systems[${index}][notes]`, coalConveyorSystem.notes)
        formData.append(`coal_conveyor_systems[${index}][operating_condition]`, coalConveyorSystem.operating_condition)
        if (coalConveyorSystem.document) {
          formData.append(`coal_conveyor_systems[${index}][document]`, coalConveyorSystem.document, coalConveyorSystem.document.name)
        } else { 
          formData.append(`coal_conveyor_systems[${index}][file_name]`, coalConveyorSystem.file_name)
          formData.append(`coal_conveyor_systems[${index}][file_size]`, coalConveyorSystem.file_size)
          formData.append(`coal_conveyor_systems[${index}][file_type]`, coalConveyorSystem.file_type)
          formData.append(`coal_conveyor_systems[${index}][uploaded_file_name]`, coalConveyorSystem.uploaded_file_name)
          formData.append(`coal_conveyor_systems[${index}][asset_path]`, coalConveyorSystem.asset_path)
          formData.append(`coal_conveyor_systems[${index}][asset_url]`, coalConveyorSystem.asset_url)
        }
        formData.append(`coal_conveyor_systems[${index}][document_control]`, coalConveyorSystem.document_control)
      })
      
      const action = request.id ? `edit/${request.id}` : "add"
      const response = await httpInstance.post(`coal-conveyor-systems/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const approveCoalConveyorSystem = createAsyncThunk(
  "coalConveyorSystems/approve",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.coal_conveyor_systems.map((coalConveyorSystem, index) => {
        formData.append(`coal_conveyor_systems[${index}][equipment_id]`, coalConveyorSystem.equipment_id)
        formData.append(`coal_conveyor_systems[${index}][physical_condition]`, coalConveyorSystem.physical_condition)
        formData.append(`coal_conveyor_systems[${index}][notes]`, coalConveyorSystem.notes)
        formData.append(`coal_conveyor_systems[${index}][operating_condition]`, coalConveyorSystem.operating_condition)
        if (coalConveyorSystem.document) {
          formData.append(`coal_conveyor_systems[${index}][document]`, coalConveyorSystem.document, coalConveyorSystem.document.name)
        } else { 
          formData.append(`coal_conveyor_systems[${index}][file_name]`, coalConveyorSystem.file_name)
          formData.append(`coal_conveyor_systems[${index}][file_size]`, coalConveyorSystem.file_size)
          formData.append(`coal_conveyor_systems[${index}][file_type]`, coalConveyorSystem.file_type)
          formData.append(`coal_conveyor_systems[${index}][uploaded_file_name]`, coalConveyorSystem.uploaded_file_name)
          formData.append(`coal_conveyor_systems[${index}][asset_path]`, coalConveyorSystem.asset_path)
          formData.append(`coal_conveyor_systems[${index}][asset_url]`, coalConveyorSystem.asset_url)
        }
        formData.append(`coal_conveyor_systems[${index}][document_control]`, coalConveyorSystem.document_control)
      })
      
      const action = `approve/${request.id}`
      const response = await httpInstance.post(`coal-conveyor-systems/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      console.log(exception)
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteCoalConveyorSystem = createAsyncThunk(
  "coalConveyorSystems/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`coal-conveyor-systems/delete/${request.query.id}`, null, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
