import {
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit"
import { fetchEntities } from "../../repositories/entityRepository"

const dataAdapter = createEntityAdapter()
const entitySlice = createSlice({
  name: "datasource/entities",
  initialState: dataAdapter.getInitialState({
    pagination: {}
  }),
  extraReducers: {
    [fetchEntities.fulfilled]: (entities, action) => {
      if (action.payload.hasOwnProperty("entities")) {
        dataAdapter.setAll(entities, action.payload.entities)
      }

      if (action.payload.hasOwnProperty("pagination")) {
        entities.pagination = action.payload.pagination
      }
    }
  }
})
export default entitySlice.reducer

const selftState = (state) => state.datasource.entity
export const getEntityIds = dataAdapter.getSelectors(selftState).selectIds
export const getEntities = dataAdapter.getSelectors(selftState).selectEntities
export const getEntityById = (id) => createSelector(
  state => dataAdapter.getSelectors(state => state.datasource.entity).selectById(state, id),
  entity => entity
)
export const getEntityByDistrict = (districtId) => createSelector(
  dataAdapter.getSelectors(selftState).selectAll,
  entity => entity.filter(data => data.district_id === districtId)
)
export const getEntityPagination = createSelector(selftState, entity => entity.pagination)
