import { combineReducers } from "@reduxjs/toolkit"

import authentication from "./datasource/authentication"
import company from "./datasource/company"
import district from "./datasource/district"
import entity from "./datasource/entity"
import equipment from "./datasource/equipment"
import sparepart from "./datasource/sparepart"
import unit from "./datasource/unit"
import permission from "./datasource/permission"
import role from "./datasource/role"
import userHistory from "./datasource/userHistory"
import user from "./datasource/user"
import shipUnloader from "./datasource/shipUnloader"
import coalConveyorSystem from "./datasource/coalConveyorSystem"
import navigation from "./datasource/navigation"
import fender from "./datasource/fender"
import heavyEquipment from "./datasource/heavyEquipment"
import equipmentEffectiveness from "./datasource/equipmentEffectiveness"
import material from "./datasource/material"
import myTask from "./datasource/myTask"
import myTransaction from "./datasource/myTransaction"
import myMaterialTask from "./datasource/myMaterialTask"
import myMaterialTransaction from "./datasource/myMaterialTransaction"
import layout from "./ui/layout"
import navbar from "./ui/navbar"

const rootReducer = combineReducers({
  datasource: combineReducers({
    authentication,
    company,
    district,
    entity,
    equipment,
    sparepart,
    unit,
    permission,
    role,
    userHistory,
    user,
    shipUnloader,
    coalConveyorSystem,
    navigation,
    fender,
    heavyEquipment,
    equipmentEffectiveness,
    material,
    myTask,
    myTransaction,
    myMaterialTask,
    myMaterialTransaction
  }),
  ui: combineReducers({
    layout,
    navbar
  })
})
export default rootReducer
