import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchPermissions } from "../../repositories/permissionRepository"

const dataAdapter = createEntityAdapter()
const permissionSlice = createSlice({
    name: "datasource/permissions",
    initialState: dataAdapter.getInitialState({
        pagination: {}
    }),
    extraReducers: {
        [fetchPermissions.fulfilled]: (permissions, action) => {
        if (action.payload.hasOwnProperty("permissions")) {
            dataAdapter.setAll(permissions, action.payload.permissions)
        }

        if (action.payload.hasOwnProperty("pagination")) {
            permissions.pagination = action.payload.pagination
        }
        }
    }
})
export default permissionSlice.reducer

const selftState = (state) => state.datasource.permission
export const getPermissionIds = dataAdapter.getSelectors(selftState).selectIds
export const getPermissions = dataAdapter.getSelectors(selftState).selectEntities
export const getPermissionById = (id) => createSelector(
    state => dataAdapter.getSelectors(state => state.datasource.permission).selectById(state, id),
    permission => permission
)
export const getPermissionPagination = createSelector(selftState, permission => permission.pagination)
