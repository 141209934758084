import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchHeavyEquipments } from "../../repositories/heavyEquipment"
  
  const dataAdapter = createEntityAdapter()
  const heavyEquipmentSlice = createSlice({
    name: "datasource/heavyEquipments",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchHeavyEquipments.fulfilled]: (heavyEquipments, action) => {
        if (action.payload.hasOwnProperty("heavyEquipments")) {
          dataAdapter.setAll(heavyEquipments, action.payload.heavyEquipments)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          heavyEquipments.pagination = action.payload.pagination
        }
      }
    }
  })
  export default heavyEquipmentSlice.reducer
  
  const selftState = (state) => state.datasource.heavyEquipment
  export const getHeavyEquipmentIds = dataAdapter.getSelectors(selftState).selectIds
  export const getHeavyEquipments = dataAdapter.getSelectors(selftState).selectEntities
  export const getHeavyEquipmentById = (id) => createSelector(
    state => dataAdapter.getSelectors(state => state.datasource.heavyEquipment).selectById(state, id),
    heavyEquipment => heavyEquipment
  )
  export const getHeavyEquipmentPagination = createSelector(selftState, heavyEquipment => heavyEquipment.pagination)
  