import {
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit"
import { fetchUnits } from "../../repositories/unitRepostitory"

const dataAdapter = createEntityAdapter()
const unitSlice = createSlice({
  name: "datasource/units",
  initialState: dataAdapter.getInitialState({
    pagination: {}
  }),
  extraReducers: {
    [fetchUnits.fulfilled]: (units, action) => {
      if (action.payload.hasOwnProperty("units")) {
        dataAdapter.setAll(units, action.payload.units)
      }

      if (action.payload.hasOwnProperty("pagination")) {
        units.pagination = action.payload.pagination
      }
    }
  }
})
export default unitSlice.reducer

const selftState = (state) => state.datasource.unit
export const getUnitIds = dataAdapter.getSelectors(selftState).selectIds
export const getUnits = dataAdapter.getSelectors(selftState).selectEntities
export const getUnitById = (id) => createSelector(
  state => dataAdapter.getSelectors(state => state.datasource.unit).selectById(state, id),
  unit => unit
)
export const getUnitByEntity = (entityId) => createSelector(
  dataAdapter.getSelectors(selftState).selectAll,
  unit => unit.filter(data => data.entity_id === entityId)
)
export const getUnitPagination = createSelector(selftState, unit => unit.pagination)
