import { createAsyncThunk } from "@reduxjs/toolkit"
import moment from "moment"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchShipUnloaders = createAsyncThunk(
  "shipUnloader/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("ship-unloaders/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchShipUnloader = createAsyncThunk(
  "shipUnloader/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("ship-unloaders/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const exportShipUnloader = createAsyncThunk(
  "shipUnloaders/export",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get(`ship-unloaders/export/${request.id}`, {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveShipUnloader = createAsyncThunk(
  "shipUnloader/save",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.ship_unloaders.map((shipUnloader, index) => {
        formData.append(`ship_unloaders[${index}][equipment_id]`, shipUnloader.equipment_id)
        formData.append(`ship_unloaders[${index}][physical_condition]`, shipUnloader.physical_condition)
        formData.append(`ship_unloaders[${index}][notes]`, shipUnloader.notes)
        formData.append(`ship_unloaders[${index}][operating_condition]`, shipUnloader.operating_condition)
        if (shipUnloader.document) {
          formData.append(`ship_unloaders[${index}][document]`, shipUnloader.document, shipUnloader.document.name)
        } else { 
          formData.append(`ship_unloaders[${index}][file_name]`, shipUnloader.file_name)
          formData.append(`ship_unloaders[${index}][file_size]`, shipUnloader.file_size)
          formData.append(`ship_unloaders[${index}][file_type]`, shipUnloader.file_type)
          formData.append(`ship_unloaders[${index}][uploaded_file_name]`, shipUnloader.uploaded_file_name)
          formData.append(`ship_unloaders[${index}][asset_path]`, shipUnloader.asset_path)
          formData.append(`ship_unloaders[${index}][asset_url]`, shipUnloader.asset_url)
        }
        formData.append(`ship_unloaders[${index}][document_control]`, shipUnloader.document_control)
      })
      
      const action = request.id ? `edit/${request.id}` : "add"
      const response = await httpInstance.post(`ship-unloaders/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const approveShipUnloader = createAsyncThunk(
  "shipUnloader/approve",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.ship_unloaders.map((shipUnloader, index) => {
        formData.append(`ship_unloaders[${index}][equipment_id]`, shipUnloader.equipment_id)
        formData.append(`ship_unloaders[${index}][physical_condition]`, shipUnloader.physical_condition)
        formData.append(`ship_unloaders[${index}][notes]`, shipUnloader.notes)
        formData.append(`ship_unloaders[${index}][operating_condition]`, shipUnloader.operating_condition)
        if (shipUnloader.document) {
          formData.append(`ship_unloaders[${index}][document]`, shipUnloader.document, shipUnloader.document.name)
        } else { 
          formData.append(`ship_unloaders[${index}][file_name]`, shipUnloader.file_name)
          formData.append(`ship_unloaders[${index}][file_size]`, shipUnloader.file_size)
          formData.append(`ship_unloaders[${index}][file_type]`, shipUnloader.file_type)
          formData.append(`ship_unloaders[${index}][uploaded_file_name]`, shipUnloader.uploaded_file_name)
          formData.append(`ship_unloaders[${index}][asset_path]`, shipUnloader.asset_path)
          formData.append(`ship_unloaders[${index}][asset_url]`, shipUnloader.asset_url)
        }
        formData.append(`ship_unloaders[${index}][document_control]`, shipUnloader.document_control)
      })
      
      const action = `approve/${request.id}`
      const response = await httpInstance.post(`ship-unloaders/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      console.log(exception)
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteShipUnloader = createAsyncThunk(
  "shipUnloader/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`ship-unloaders/delete/${request.query.id}`, null, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
