import { createAsyncThunk } from "@reduxjs/toolkit"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchMaterials = createAsyncThunk(
  "materials/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("adjustment-spareparts/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchMyMaterialTasks = createAsyncThunk(
  "materials/fetchMyMaterialTask",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("adjustment-spareparts/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchMyMaterialTransactions = createAsyncThunk(
  "materials/fetchMyMaterialTransactions",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("adjustment-spareparts/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchMaterial = createAsyncThunk(
  "materials/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("adjustment-spareparts/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const exportMaterial = createAsyncThunk(
  "materials/export",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get(`adjustment-spareparts/export/${request.id}`, {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveMaterial = createAsyncThunk(
  "materials/save",
  async (request, thunkAPI) => {
    try {
      const formData = { ...request }
      const action = formData.id ? `edit/${formData.id}` : "add"
      const response = await httpInstance.post(`adjustment-spareparts/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const approveMaterial = createAsyncThunk(
  "materials/approve",
  async (request, thunkAPI) => {
    try {
      const formData = { ...request }
      const action = `approve/${request.id}`
      const response = await httpInstance.post(`adjustment-spareparts/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteMaterial = createAsyncThunk(
  "materials/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`adjustment-spareparts/delete/${request.query.id}`, null, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)