import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchMaterials } from "../../repositories/materialRepository"
  
const dataAdapter = createEntityAdapter()
const materialSlice = createSlice({
    name: "datasource/materials",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchMaterials.fulfilled]: (materials, action) => {
        if (action.payload.hasOwnProperty("adjustmentSpareparts")) {
          dataAdapter.setAll(materials, action.payload.adjustmentSpareparts)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          materials.pagination = action.payload.pagination
        }
      }
    }
})
export default materialSlice.reducer
  
const selftState = (state) => state.datasource.material
export const getMaterialIds = dataAdapter.getSelectors(selftState).selectIds
export const getMaterials = dataAdapter.getSelectors(selftState).selectEntities
export const getMaterialById = (id) => createSelector(
    state => dataAdapter.getSelectors(state => state.datasource.material).selectById(state, id),
    material => material
)
export const getMaterialPagination = createSelector(selftState, material => material.pagination)
  