import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchUserHistories } from "../../repositories/userHistoryRepository"
  
const dataAdapter = createEntityAdapter()
const userHistorieSlice = createSlice({
  name: "datasource/userHistories",
  initialState: dataAdapter.getInitialState({
    pagination: {}
  }),
  extraReducers: {
    [fetchUserHistories.fulfilled]: (userHistories, action) => {
      if (action.payload.hasOwnProperty("userHistories")) {
        dataAdapter.setAll(userHistories, action.payload.userHistories)
      }

      if (action.payload.hasOwnProperty("pagination")) {
        userHistories.pagination = action.payload.pagination
      }
    }
  }
})
export default userHistorieSlice.reducer

const selftState = (state) => state.datasource.userHistory
export const getUserHistoryIds = dataAdapter.getSelectors(selftState).selectIds
export const getUserHistories = dataAdapter.getSelectors(selftState).selectEntities
export const getUserHistoryById = (id) => createSelector(
  state => dataAdapter.getSelectors(state => state.datasource.userHistory).selectById(state, id),
  userHistory => userHistory
)
export const getUserHistoryPagination = createSelector(selftState, userHistory => userHistory.pagination)
