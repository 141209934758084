import {
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit"
import { fetchDistricts } from "../../repositories/districtRepository"

const dataAdapter = createEntityAdapter()
const districtSlice = createSlice({
  name: "datasource/districts",
  initialState: dataAdapter.getInitialState({
    pagination: {}
  }),
  extraReducers: {
    [fetchDistricts.fulfilled]: (districts, action) => {
      if (action.payload.hasOwnProperty("districts")) {
        dataAdapter.setAll(districts, action.payload.districts)
      }

      if (action.payload.hasOwnProperty("pagination")) {
        districts.pagination = action.payload.pagination
      }
    }
  }
})
export default districtSlice.reducer

const selftState = state => state.datasource.district
export const getDistrictIds = dataAdapter.getSelectors(selftState).selectIds
export const getDistricts = dataAdapter.getSelectors(selftState).selectEntities
export const getDistrictById = (id) => createSelector(
  state => dataAdapter.getSelectors(state => state.datasource.district).selectById(state, id),
  district => district
)
export const getDistrictPagination = createSelector(selftState, district => district.pagination)
