import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchCoalConveyorSystems } from "../../repositories/coalConveyorSystemRepository"
  
  const dataAdapter = createEntityAdapter()
  const coalConveyorSystemSlice = createSlice({
    name: "datasource/coalConveyorSystems",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchCoalConveyorSystems.fulfilled]: (coalConveyorSystems, action) => {
        if (action.payload.hasOwnProperty("coalConveyorSystems")) {
          dataAdapter.setAll(coalConveyorSystems, action.payload.coalConveyorSystems)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          coalConveyorSystems.pagination = action.payload.pagination
        }
      }
    }
  })
  export default coalConveyorSystemSlice.reducer
  
  const selftState = (state) => state.datasource.coalConveyorSystem
  export const getCoalConveyorSystemIds = dataAdapter.getSelectors(selftState).selectIds
  export const getCoalConveyorSystems = dataAdapter.getSelectors(selftState).selectEntities
  export const getCoalConveyorSystemById = (id) => createSelector(
    state => dataAdapter.getSelectors(state => state.datasource.coalConveyorSystem).selectById(state, id),
    coalConveyorSystem => coalConveyorSystem
  )
  export const getCoalConveyorSystemPagination = createSelector(selftState, coalConveyorSystem => coalConveyorSystem.pagination)
  