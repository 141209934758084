import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchMyMaterialTasks } from "../../repositories/materialRepository"
  
const dataAdapter = createEntityAdapter()
const myMaterialTaskSlice = createSlice({
    name: "datasource/myMaterialTask",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchMyMaterialTasks.fulfilled]: (myMaterialTasks, action) => {
        if (action.payload.hasOwnProperty("adjustmentSpareparts")) {
          dataAdapter.setAll(myMaterialTasks, action.payload.adjustmentSpareparts)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          myMaterialTasks.pagination = action.payload.pagination
        }
      }
    }
})
export default myMaterialTaskSlice.reducer
  
const selftState = state => state.datasource.myMaterialTask
export const getMyMaterialTaskIds = dataAdapter.getSelectors(selftState).selectIds
export const getMyMaterialTasks = dataAdapter.getSelectors(selftState).selectEntities
export const getMyMaterialTaskPagination = createSelector(selftState, myMaterialTask => myMaterialTask.pagination)
  