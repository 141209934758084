import { createAsyncThunk } from "@reduxjs/toolkit"
import moment from "moment"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchHeavyEquipments = createAsyncThunk(
  "heavy-equipment/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("heavy-equipment/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchHeavyEquipment = createAsyncThunk(
  "heavy-equipment/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("heavy-equipment/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const exportHeavyEquipment = createAsyncThunk(
  "heavy-equipment/export",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get(`heavy-equipment/export/${request.id}`, {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveHeavyEquipment = createAsyncThunk(
  "heavy-equipment/save",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.heavy_equipment.map((heavyEquipment, index) => {
        formData.append(`heavy_equipment[${index}][equipment_id]`, heavyEquipment.equipment_id)
        formData.append(`heavy_equipment[${index}][engine]`, heavyEquipment.engine)
        formData.append(`heavy_equipment[${index}][transmission]`, heavyEquipment.transmission)
        formData.append(`heavy_equipment[${index}][hydraulic]`, heavyEquipment.hydraulic)
        formData.append(`heavy_equipment[${index}][electrical]`, heavyEquipment.electrical)
        formData.append(`heavy_equipment[${index}][notes]`, heavyEquipment.notes)
        formData.append(`heavy_equipment[${index}][operating_condition]`, heavyEquipment.operating_condition)
        formData.append(`heavy_equipment[${index}][point]`, heavyEquipment.point)
        if (heavyEquipment.document) {
          formData.append(`heavy_equipment[${index}][document]`, heavyEquipment.document, heavyEquipment.document.name)
        } else { 
          formData.append(`heavy_equipment[${index}][file_name]`, heavyEquipment.file_name)
          formData.append(`heavy_equipment[${index}][file_size]`, heavyEquipment.file_size)
          formData.append(`heavy_equipment[${index}][file_type]`, heavyEquipment.file_type)
          formData.append(`heavy_equipment[${index}][uploaded_file_name]`, heavyEquipment.uploaded_file_name)
          formData.append(`heavy_equipment[${index}][asset_path]`, heavyEquipment.asset_path)
          formData.append(`heavy_equipment[${index}][asset_url]`, heavyEquipment.asset_url)
        }
        formData.append(`heavy_equipment[${index}][document_control]`, heavyEquipment.document_control)
      })
      
      const action = request.id ? `edit/${request.id}` : "add"
      const response = await httpInstance.post(`heavy-equipment/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const approveHeavyEquipment = createAsyncThunk(
  "heavy-equipment/approve",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.heavy_equipment.map((heavyEquipment, index) => {
        formData.append(`heavy_equipment[${index}][equipment_id]`, heavyEquipment.equipment_id)
        formData.append(`heavy_equipment[${index}][engine]`, heavyEquipment.engine)
        formData.append(`heavy_equipment[${index}][transmission]`, heavyEquipment.transmission)
        formData.append(`heavy_equipment[${index}][hydraulic]`, heavyEquipment.hydraulic)
        formData.append(`heavy_equipment[${index}][electrical]`, heavyEquipment.electrical)
        formData.append(`heavy_equipment[${index}][notes]`, heavyEquipment.notes)
        formData.append(`heavy_equipment[${index}][operating_condition]`, heavyEquipment.operating_condition)
        formData.append(`heavy_equipment[${index}][point]`, heavyEquipment.point)
        if (heavyEquipment.document) {
          formData.append(`heavy_equipment[${index}][document]`, heavyEquipment.document, heavyEquipment.document.name)
        } else { 
          formData.append(`heavy_equipment[${index}][file_name]`, heavyEquipment.file_name)
          formData.append(`heavy_equipment[${index}][file_size]`, heavyEquipment.file_size)
          formData.append(`heavy_equipment[${index}][file_type]`, heavyEquipment.file_type)
          formData.append(`heavy_equipment[${index}][uploaded_file_name]`, heavyEquipment.uploaded_file_name)
          formData.append(`heavy_equipment[${index}][asset_path]`, heavyEquipment.asset_path)
          formData.append(`heavy_equipment[${index}][asset_url]`, heavyEquipment.asset_url)
        }
        formData.append(`heavy_equipment[${index}][document_control]`, heavyEquipment.document_control)
      })
      
      const action = `approve/${request.id}`
      const response = await httpInstance.post(`heavy-equipment/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteHeavyEquipment = createAsyncThunk(
  "heavy-equipment/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`heavy-equipment/delete/${request.query.id}`, null, httpConfiguration())
      
      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
