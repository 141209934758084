import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchShipUnloaders } from "../../repositories/shipUnloaderRepository"
  
  const dataAdapter = createEntityAdapter()
  const shipUnloaderSlice = createSlice({
    name: "datasource/shipUnloaders",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchShipUnloaders.fulfilled]: (shipUnloaders, action) => {
        if (action.payload.hasOwnProperty("shipUnloaders")) {
          dataAdapter.setAll(shipUnloaders, action.payload.shipUnloaders)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          shipUnloaders.pagination = action.payload.pagination
        }
      }
    }
  })
  export default shipUnloaderSlice.reducer
  
  const selftState = (state) => state.datasource.shipUnloader
  export const getShipUnloaderIds = dataAdapter.getSelectors(selftState).selectIds
  export const getShipUnloaders = dataAdapter.getSelectors(selftState).selectEntities
  export const getShipUnloaderById = (id) => createSelector(
    state => dataAdapter.getSelectors(state => state.datasource.shipUnloader).selectById(state, id),
    shipUnloader => shipUnloader
  )
  export const getShipUnloaderPagination = createSelector(selftState, shipUnloader => shipUnloader.pagination)
  