import {
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit"
import { fetchEquipment } from "../../repositories/equipmentRepository"

const dataAdapter = createEntityAdapter()
const equipmentSlice = createSlice({
  name: "datasource/equipment",
  initialState: dataAdapter.getInitialState({
    pagination: {}
  }),
  reducers: {
    unloaded: equipment => {
      dataAdapter.removeAll(equipment, {})
    }
  },
  extraReducers: {
    [fetchEquipment.fulfilled]: (equipment, action) => {
      if (action.payload.hasOwnProperty("equipment")) {
        dataAdapter.setAll(equipment, action.payload.equipment)
      }

      if (action.payload.hasOwnProperty("pagination")) {
        equipment.pagination = action.payload.pagination
      }
    }
  }
})
export default equipmentSlice.reducer

const selftState = (state) => state.datasource.equipment
export const unloadEquipment = () => equipmentSlice.actions.unloaded()
export const getEquipmentIds = dataAdapter.getSelectors(selftState).selectIds
export const getEquipment = dataAdapter.getSelectors(selftState).selectEntities
export const getEquipmentById = (id) => createSelector(
  state => dataAdapter.getSelectors(state => state.datasource.equipment).selectById(state, id),
  equipment => equipment
)
export const getEquipmentPagination = createSelector(selftState, equipment => equipment.pagination)
export const getEquipmentLoadingStatus = createSelector(selftState, equipment => equipment.loading)
