import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchNavigations } from "../../repositories/navigationRepository"
  
  const dataAdapter = createEntityAdapter()
  const navigationSlice = createSlice({
    name: "datasource/navigations",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchNavigations.fulfilled]: (navigations, action) => {
        if (action.payload.hasOwnProperty("navigations")) {
          dataAdapter.setAll(navigations, action.payload.navigations)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          navigations.pagination = action.payload.pagination
        }
      }
    }
  })
  export default navigationSlice.reducer
  
  const selftState = (state) => state.datasource.navigation
  export const getNavigationIds = dataAdapter.getSelectors(selftState).selectIds
  export const getNavigations = dataAdapter.getSelectors(selftState).selectEntities
  export const getNavigationById = (id) => createSelector(
    state => dataAdapter.getSelectors(state => state.datasource.navigation).selectById(state, id),
    navigation => navigation
  )
  export const getNavigationPagination = createSelector(selftState, navigation => navigation.pagination)
  