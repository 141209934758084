import { createAsyncThunk } from "@reduxjs/toolkit"
import moment from "moment"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchFenders = createAsyncThunk(
  "fenders/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("fenders/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchFender = createAsyncThunk(
  "fenders/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("fenders/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const exportFender = createAsyncThunk(
  "fenders/export",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get(`fenders/export/${request.id}`, {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveFender = createAsyncThunk(
  "fenders/save",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.fenders.map((fender, index) => {
        formData.append(`fenders[${index}][equipment_id]`, fender.equipment_id)
        formData.append(`fenders[${index}][rubber]`, fender.rubber)
        formData.append(`fenders[${index}][front_pad]`, fender.front_pad)
        formData.append(`fenders[${index}][chain]`, fender.chain)
        formData.append(`fenders[${index}][anchor_bolt]`, fender.anchor_bolt)
        formData.append(`fenders[${index}][notes]`, fender.notes)
        formData.append(`fenders[${index}][operating_condition]`, fender.operating_condition)
        formData.append(`fenders[${index}][point]`, fender.point)
        if (fender.document) {
          formData.append(`fenders[${index}][document]`, fender.document, fender.document.name)
        } else { 
          formData.append(`fenders[${index}][file_name]`, fender.file_name)
          formData.append(`fenders[${index}][file_size]`, fender.file_size)
          formData.append(`fenders[${index}][file_type]`, fender.file_type)
          formData.append(`fenders[${index}][uploaded_file_name]`, fender.uploaded_file_name)
          formData.append(`fenders[${index}][asset_path]`, fender.asset_path)
          formData.append(`fenders[${index}][asset_url]`, fender.asset_url)
        }
        formData.append(`fenders[${index}][document_control]`, fender.document_control)
      })
      
      const action = request.id ? `edit/${request.id}` : "add"
      const response = await httpInstance.post(`fenders/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const approveFender = createAsyncThunk(
  "fenders/approve",
  async (request, thunkAPI) => {
    try {
      const formData = new FormData()

      if (request.id) {
        formData.append('id', request.id)
      }
      formData.append('equipment_id', request.equipment_id)
      formData.append('user_id', request.user_id)
      formData.append('equipment_effectiveness_date', moment(request.equipment_effectiveness_date).format("YYYY-MM-DD"))
      formData.append('notes', request.notes)
      request.fenders.map((fender, index) => {
        formData.append(`fenders[${index}][equipment_id]`, fender.equipment_id)
        formData.append(`fenders[${index}][rubber]`, fender.rubber)
        formData.append(`fenders[${index}][front_pad]`, fender.front_pad)
        formData.append(`fenders[${index}][chain]`, fender.chain)
        formData.append(`fenders[${index}][anchor_bolt]`, fender.anchor_bolt)
        formData.append(`fenders[${index}][notes]`, fender.notes)
        formData.append(`fenders[${index}][operating_condition]`, fender.operating_condition)
        formData.append(`fenders[${index}][point]`, fender.point)
        if (fender.document) {
          formData.append(`fenders[${index}][document]`, fender.document, fender.document.name)
        } else { 
          formData.append(`fenders[${index}][file_name]`, fender.file_name)
          formData.append(`fenders[${index}][file_size]`, fender.file_size)
          formData.append(`fenders[${index}][file_type]`, fender.file_type)
          formData.append(`fenders[${index}][uploaded_file_name]`, fender.uploaded_file_name)
          formData.append(`fenders[${index}][asset_path]`, fender.asset_path)
          formData.append(`fenders[${index}][asset_url]`, fender.asset_url)
        }
        formData.append(`fenders[${index}][document_control]`, fender.document_control)
      })
      
      const action = `approve/${request.id}`
      const response = await httpInstance.post(`fenders/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteFender = createAsyncThunk(
  "fenders/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`fenders/delete/${request.query.id}`, null, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
