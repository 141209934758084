import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchDetailTransaction } from "../../repositories/equipmentEffectivenessRepository"
  
  const dataAdapter = createEntityAdapter()
  const equipmentEffectivenessSlice = createSlice({
    name: "datasource/equipmentEffectiveness",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchDetailTransaction.fulfilled]: (equipmentEffectivenesses, action) => {
        if (action.payload.hasOwnProperty("equipmentEffectivenesses")) {
          dataAdapter.setAll(equipmentEffectivenesses, action.payload.equipmentEffectivenesses)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          equipmentEffectivenesses.pagination = action.payload.pagination
        }
      }
    }
  })
  export default equipmentEffectivenessSlice.reducer
  
  const selftState = (state) => state.datasource.equipmentEffectiveness
  export const getEquipmentEffectivenessIds = dataAdapter.getSelectors(selftState).selectIds
  export const getEquipmentEffectivenesses = dataAdapter.getSelectors(selftState).selectEntities
  export const getEquipmentEffectivenessById = (id) => createSelector(
    state => dataAdapter.getSelectors(state => state.datasource.equipmentEffectiveness).selectById(state, id),
    equipmentEffectiveness => equipmentEffectiveness
  )
  export const getEquipmentEffectivenessPagination = createSelector(selftState, equipmentEffectiveness => equipmentEffectiveness.pagination)
  