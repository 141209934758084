import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchMyMaterialTransactions } from "../../repositories/materialRepository"
  
const dataAdapter = createEntityAdapter()
const myMaterialTransactionSlice = createSlice({
    name: "datasource/myMaterialTransactions",
    initialState: dataAdapter.getInitialState({
      pagination: {}
    }),
    extraReducers: {
      [fetchMyMaterialTransactions.fulfilled]: (myMaterialTransactions, action) => {
        if (action.payload.hasOwnProperty("adjustmentSpareparts")) {
          dataAdapter.setAll(myMaterialTransactions, action.payload.adjustmentSpareparts)
        }
  
        if (action.payload.hasOwnProperty("pagination")) {
          myMaterialTransactions.pagination = action.payload.pagination
        }
      }
    }
})
export default myMaterialTransactionSlice.reducer
  
const selftState = state => state.datasource.myMaterialTransaction
export const getMyMaterialTransactionIds = dataAdapter.getSelectors(selftState).selectIds
export const getMyMaterialTransactions = dataAdapter.getSelectors(selftState).selectEntities
export const getMyMaterialTransactionPagination = createSelector(selftState, myMaterialTransaction => myMaterialTransaction.pagination)
  