import { createAsyncThunk } from "@reduxjs/toolkit"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchDistricts = createAsyncThunk(
  "districts/fetches",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("districts/index", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const fetchDistrict = createAsyncThunk(
  "districts/fetch",
  async (request, thunkAPI) => {
    try {
      const params = request ? { params: request.query } : {}
      const response = await httpInstance.get("districts/view", {
        ...params,
        ...httpConfiguration()
      })

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const saveDistrict = createAsyncThunk(
  "districts/save",
  async (request, thunkAPI) => {
    try {
      const formData = { ...request }
      const action = formData.id ? `edit/${formData.id}` : "add"
      const response = await httpInstance.post(`districts/${action}`, formData, httpConfiguration())

      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)

export const deleteDistrict = createAsyncThunk(
  "districts/delete",
  async (request, thunkAPI) => {
    try {
      const response = await httpInstance.post(`districts/delete/${request.query.id}`, null, httpConfiguration())
      
      return response.data
    } catch (exception) {
      return thunkAPI.rejectWithValue(exception.response.data)
    }
  }
)
