import {
    createEntityAdapter,
    createSelector,
    createSlice
} from "@reduxjs/toolkit"
import { fetchUsers } from "../../repositories/userRepository"
  
const dataAdapter = createEntityAdapter()
const userSlice = createSlice({
  name: "datasource/users",
  initialState: dataAdapter.getInitialState({
    pagination: {}
  }),
  extraReducers: {
    [fetchUsers.fulfilled]: (users, action) => {
      if (action.payload.hasOwnProperty("users")) {
        dataAdapter.setAll(users, action.payload.users)
      }

      if (action.payload.hasOwnProperty("pagination")) {
        users.pagination = action.payload.pagination
      }
    }
  }
})
export default userSlice.reducer

const selftState = (state) => state.datasource.user
export const getUserIds = dataAdapter.getSelectors(selftState).selectIds
export const getUsers = dataAdapter.getSelectors(selftState).selectEntities
export const getUserById = (id) => createSelector(
  state => dataAdapter.getSelectors(state => state.datasource.user).selectById(state, id),
  user => user
)
export const getUserPagination = createSelector(selftState, user => user.pagination)
