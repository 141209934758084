import { createAsyncThunk } from "@reduxjs/toolkit"
import httpInstance, { httpConfiguration } from "../httpClient"

export const fetchMyTasks = createAsyncThunk(
    "equipmentEffectiveness/fetchMyTask",
    async (request, thunkAPI) => {
      try {
        const params = request ? { params: request.query } : {}
        const response = await httpInstance.get("equipment-effectivenesses/index", {
          ...params,
          ...httpConfiguration()
        })
  
        return response.data
      } catch (exception) {
        return thunkAPI.rejectWithValue(exception.response.data)
      }
    }
  )

export const fetchMyTransactions = createAsyncThunk(
    "equipmentEffectiveness/fetchMyTransactions",
    async (request, thunkAPI) => {
      try {
        const params = request ? { params: request.query } : {}
        const response = await httpInstance.get("equipment-effectivenesses/index", {
          ...params,
          ...httpConfiguration()
        })
  
        return response.data
      } catch (exception) {
        return thunkAPI.rejectWithValue(exception.response.data)
      }
    }
  )

export const fetchTransactionStatuses = createAsyncThunk(
    "equipmentEffectiveness/fetchTransactionStatuses",
    async (request, thunkAPI) => {
      try {
        const params = request ? { params: request.query } : {}
        const response = await httpInstance.get("equipment-effectivenesses/status", {
          ...params,
          ...httpConfiguration()
        })
  
        return response.data
      } catch (exception) {
        return thunkAPI.rejectWithValue(exception.response.data)
      }
    }
  )

export const fetchDetailTransaction = createAsyncThunk(
    "equipmentEffectiveness/fetchDetailTransaction",
    async (request, thunkAPI) => {
      try {
        const params = request ? { params: request.query } : {}
        const response = await httpInstance.get("equipment-effectivenesses/index", {
          ...params,
          ...httpConfiguration()
        })
  
        return response.data
      } catch (exception) {
        return thunkAPI.rejectWithValue(exception.response.data)
      }
    }
  )
