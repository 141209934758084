import {
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit"
import { fetchCompanies } from "../../repositories/companyRepository"

const dataAdapter = createEntityAdapter()
const companySlice = createSlice({
  name: "datasource/companies",
  initialState: dataAdapter.getInitialState({
    pagination: {}
  }),
  extraReducers: {
    [fetchCompanies.fulfilled]: (companies, action) => {
      if (action.payload.hasOwnProperty("companies")) {
        dataAdapter.setAll(companies, action.payload.companies)
      }

      if (action.payload.hasOwnProperty("pagination")) {
        companies.pagination = action.payload.pagination
      }
    }
  }
})
export default companySlice.reducer

const selftState = (state) => state.datasource.company
export const getCompanyIds = dataAdapter.getSelectors(selftState).selectIds
export const getCompanies = dataAdapter.getSelectors(selftState).selectEntities
export const getCompanyById = (id) => createSelector(
  state => dataAdapter.getSelectors(state => state.datasource.company).selectById(state, id),
  company => company
)
export const getCompanyPagination = createSelector(selftState, company => company.pagination)
